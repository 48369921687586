<template>
	<!--  授信额度 -->

	<div class="BillWallet-Box padding-lr-13 margin-t-20 theme-themes_blue" style="margin-top: 20px;">
		<div class="title font-size18 font-color-333 font-weight700" style="margin: 8px 0;height: 20px;line-height: 20px;">授信额度</div>

		<div v-if="pictureCardData.length!= 0 ">
			<el-carousel ref="carousel" trigger="click" :autoplay="false" type="card" height="200px" arrow="always" @change="linkTo">
				<el-carousel-item v-for="(item, index) in pictureCardData" :key="index">
					<div class="flex-row">
						<div class="creditLine-box position-relative margin-r-10" @click="pointList(item.fShopUnitID)">
							<img src="../../assets/credit.png" width="100%" />
							<div class="position-absolute bg-fff position-a-box"></div>
							<div class="position-absolute creditLine-cont color-4F5467">
								<div class="flex-row-space-between-center">
									<div class="font-size15 font-weight700">
										{{ item.fShopName }}
									</div>
									<!-- <div class="color-theme font-size14 pointer">授信明细></div> -->
								</div>
								<div class="flex-row font-size12">
									<div class="width-haff">
										<div>可用额度</div>
										<div class="font-size20 font-weight700 padding-5">¥{{item.fEnableUseMoney}}</div>
										<div class="color-BBBDC5">总额度&nbsp;&nbsp;¥&nbsp;&nbsp;{{ item.fLastLimitMoney | Statistic1}} </div>
									</div>
									<div class="width-haff">
										<div>待还款金额</div>
										<div class="font-size20 font-weight700 padding-5">¥{{item.fEndBalance | Statistic1}}</div>
										<div class="color-BBBDC5">最后还款日期：{{item.fNeedRepaymentDate}}</div>
									</div>
								</div>
								<div class="flex-row">
									<div class="apply-btn pointer" @click.stop="applyCreditLine(item.fShopUnitID)">申请额度</div>
									<div class="backmoney-btn margin-l-10 font-color-fff pointer" @click.stop="immediateRepayment(item)">
										立即还款
									</div>
								</div>
							</div>
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
			<!-- tab切换 -->
			<div class="tabTitleRow pointer">
				<div v-for="(item, index) in tabData" :key="index" :class="item.checked ? 'checkTitleSty' : ''" @click="selecttab(index)">
					{{ item.title }}
				</div>
			</div>
			<!-- 申请明细列表 -->
			<div v-if="listShow == 0">
				<div class="text-center font-color-ae flex-row-center-center margin-t-30" v-if="ApplyList.length == 0">
					<svg-icon icon-class="NoDataAvailable" />
					<!-- 暂无数据 -->
				</div>
				<div class="orderd font-size13" v-for="(item, index) in ApplyList" :key="index">
					<div class="flex-row margin-b-7">
						<div class="font-color-666 wid8">
							<div class="font-color-333 wid8">
								授信申请：(单据号:&nbsp;&nbsp;&nbsp;&nbsp;{{ item.fBillNumber }})
								<span class="tipsds" v-if="item.fIsDelete == 1">已作废</span>
								<span class="tipsd" v-else>{{ item.fApproveStatusName }}</span>
							</div>
						</div>
						<div class="font-color-666 wid2 ter color-C9983D">
							申请额度: ¥{{item.fCreditLimitsMoney | Statistic1}}
						</div>
						<!-- <div class="font-color-666 wid2 ter color-C9983D" v-if="item.fApproveStatus == 254">
								审批额度: ¥13,800.90
							</div> -->
					</div>
					<div class="flex-row-space-between">
						<div class="font-color-666  font-size12">
							<span>店铺: {{ item.fShopName }}</span>
							<span> 申请时间：{{ item.fCreateTime }} </span>
							<span> 最后还款日：{{ item.fRepaymentingDate }} </span>
						</div>
						<div class="font-color-666  ter  flex-row" v-if="item.fApproveStatus == 0 && item.fIsDelete != 1">
							<div class="margin-r-10">
								<div class="operation-btn font-size12 text-center border border-color-theme color-theme pointer" @click="editbtnClick(item.fRequisitionID)">
									提交审核
								</div>
							</div>
							<!-- <div class="margin-r-10 pointer">
									<div class="operation-btn font-size12 text-center border border-color-theme color-theme" @click="SubmitAuditingClick(item)">
										提交审核
									</div>
								</div> -->
							<div class="margin-r-10 pointer">
								<div class="operation-btn font-size12 text-center border border-color-theme color-theme" @click="toVoid(item.fRequisitionID)">
									作废
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="pagination flex-row-center-flex-end ">
					<el-pagination background layout="total,prev, pager, next" :page-size="maxResultCount" :total="total"
					 @current-change="handleCurrentChange"></el-pagination>
				</div>
			</div>
			<!-- 还款明细列表 -->
			<div v-else>
				<div class="tabTwo hide-text">

					<div class="orderd font-size13" v-for="(item, index) in oderList" :key="index">
						<div class="flex-row-space-between-center">
							<div class="font-size12">
								<div class="font-color-333 margin-b-7">
									还款明细 单据号:{{ item.fBillNumber }}
									<span class="tipsd">{{ item.fPlyingStatusName }}</span>
								</div>
								<div class="font-color-666">
									<span>店铺 : {{ item.fShopName }}</span>
									<span class="font-color-666">
										{{ item.fCreateTime }}
									</span>
								</div>
							</div>
							<div class="ter color-C9983D font-size14">
								¥ {{ item.fTotalMoney|NumFormat }}
							</div>
						</div>
					</div>
				</div>
				<div class="pagination flex-row-center-flex-end ">
					<el-pagination background layout="total,prev, pager, next" :page-size="maxResultCountt" :total="totalt"
					 @current-change="handleCurrentChanget"></el-pagination>
				</div>
			</div>
		</div>
		<div class="text-center font-color-ae  margin-t-30" v-else>
			<svg-icon icon-class="NoRightsAndInterests" />
			<!-- 暂无数据 -->
			<!-- <div class="text-center  font-color-666 font-size16">您还未开通授信额度 !</div> -->
			<!-- <el-button class="btncla">开通授信</el-button> -->
			<div class="text-center  font-color-666 font-size16">您尚未开通授信，请前往企业购议价申请 !</div>
		</div>



	</div>

</template>
<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		data() {
			return {
				value: "",
				listShow: 0,
				maxResultCount: 8,
				page: 1,
				total: 0,
				maxResultCountt: 8,
				paget: 1,
				totalt: 0,
				tableData: [],
				tabData: [{
						title: "申请明细",
						type: 1,
						checked: true,
					},
					{
						title: "还款明细",
						type: 2,
						checked: false,
					},
				],
				pictureCardData: [],
				ApplyList: [],
				oderList: [{
					id: "1",
					name: "(单据号：20210125123456789)",
					type: "聚材通平台店铺",
					stuts: "状态",
					numt: "实发数量",
					numtext: "聚材通自营店店铺",
					numt2: "申请数量",
					numtext2: "500.00",
					time: "2020-08-10 12:30:00",
				}, ],
				billDetailsData: [],
				shopId: 0,
				fIsShow : '',
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		mounted() {
			this.getcreditList() //客商的数据
			this.getLegalEntity()
			// this.getapplyCreditList(); //授信申请的数据
			// this.pointBillGetList();
		},
		filters: {
			Statistic1(num) {
				num = parseFloat(Number(num).toFixed(3))
				let [integer, decimal] = String.prototype.split.call(num, '.')
				integer = integer.replace(/\d(?=(\d{3})+$)/g, '$&,') // 正则先行断言
				return `${integer}${decimal ? '.' + decimal : ''}`
			},
		},
		methods: {
			linkTo() {
				let activeIndex = this.$refs.carousel.activeIndex
				console.log(this.$refs.carousel);
				console.log(activeIndex);
			},
			//tab点击事件
			selecttab(e) {
				console.log(e, "111111");
				for (let item of this.tabData) {
					item.checked = false;
				}
				this.tabData[e].checked = true;
				this.listShow = e;
				if (this.listShow == 1) {
					//this.pointBillGetList1();
				} else if (this.listShow == 0) {
					//this.pointBillGetList();
				}
			},
			//申请额度
			applyCreditLine(fShopUnitID) {
				console.log(fShopUnitID)
				this.$router.push({
					path: "/businessme/CreditLineAdd",
					query: {
						id: fShopUnitID
					},
				});
			},
			// 获取授信头部卡片数据
			getcreditList() {
				this.ApiRequestPost(
					"api/mall/ebbalance/customer-credit-limit/my-credit-limit", {}
				).then(
					(result) => {
						for (let item of result.obj) {
							item.fCreateTime = this.getTime(item.fCreateTime);
							item.fModifyTime = this.getTime(item.fModifyTime);
							item.fNeedRepaymentDate = this.getDate(item.fNeedRepaymentDate);
						}
						this.pictureCardData = result.obj;
						if (this.pictureCardData.length > 0) {
							this.shopId = this.pictureCardData[0].fShopUnitID;
							this.pointBillGetList(this.pictureCardData[0].fShopUnitID)
							this.getapplyCreditList(this.shopId)
						}
					},
					(error) => {
						//   alert(JSON.stringify(error));
					}
				);
			},
			//授信申请
			getapplyCreditList(shopId) {
				this.ApiRequestPost(
					"api/mall/ebsale/credit-limits-requisition/get-list-by-customerid", {
						maxResultCount: this.maxResultCount,
						skipCount: (this.page - 1) * this.maxResultCount,
						fShopUnitID: shopId
					}
				).then(
					(result) => {
						for (let item of result.obj.items) {
							item.fCreateTime = this.getTime(item.fCreateTime);
							item.fModifyTime = this.getTime(item.fModifyTime);
							item.fNeedRepaymentDate = this.getDate(item.fNeedRepaymentDate);
							item.fRepaymentingDate = this.getDate(item.fRepaymentingDate);

						}
						this.ApplyList = result.obj.items;
						this.total = result.obj.totalCount;
					},
					(error) => {
						//   alert(JSON.stringify(error));
					}
				);
			},
			//授信还款
			pointBillGetList(e) {
				// this.getapplyCreditList(e);
				this.ApiRequestPost(
					"api/mall/ebsale/customer-payment-bill/credit-pay-detail", {
						fShopUnitID: e,
						maxResultCount: this.maxResultCountt,
						skipCount: (this.paget - 1) * this.maxResultCountt,
					}
				).then(
					(result) => {
						console.log(result);
						for (let item of result.obj.items) {
							item.fCreateTime = this.getTime(item.fCreateTime);
							item.fModifyTime = this.getTime(item.fModifyTime);
							item.fNeedRepaymentDate = this.getDate(item.fNeedRepaymentDate);
						}
						this.oderList = result.obj.items;
						this.totalt = result.obj.totalCount;
					},
					(error) => {
						//   alert(JSON.stringify(error));
					}
				);
			},
			pointList(e) {
				this.shopId=e;
				this.getapplyCreditList(e)
				this.pointBillGetList(e)
			},
			//分页点击事件
			handleCurrentChange(val) {
				this.page = val;
				this.getapplyCreditList(this.shopId);
			},
			//分页点击事件
			handleCurrentChanget(val) {
				this.paget = val;
				this.getapplyCreditList(this.shopId);
			},
			//编辑按钮
			editbtnClick(id) {
				if(this.fIsShow){
					this.$confirm("授信申请需要法人相关信息，请前往完善！", "温馨提示", {
							confirmButtonText: "前往完善",
							cancelButtonText: "稍后完善",
							type: "warning",
						}).then(()=>{
							this.$router.push("/PersonalContent/Improve");
						}).catch(()=>{
							
						})
				}else{
					this.$router.push({
						path: "/businessme/CreditLineEdit",
						query: {
							id: id
						},
					});
				}
			},
			//获取是否有法人
			getLegalEntity() {
				this.ApiRequestPostNOMess('api/mall/ebcustomer/adjust-bill/get-curret-isshow').then(
					res => {
						if(res.obj.fIsShow == 0){
							this.fIsShow = false
						}else{
							this.fIsShow = true
						}
					}
				);
			},
			//提交审核事件
			SubmitAuditingClick(row) {
				console.log(row, "0000")
				if (row.fApproveStatus == 1) {
					this.$message("状态为审核中不能提交审核");
					return false;
				}
				if (row.fStatus == 1) {
					this.$message("已生效不能提交审核");
					return false;
				}
				this.$confirm("是否确认提交审核?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.ApiRequestPut(
							"api/mall/ebsale/credit-limits-requisition/submit-audit", [{
								id: row.fRequisitionID,
							}]
						).then(
							(result) => {
								this.getapplyCreditList(this.shopId);
							},
							(error) => {
								// alert(JSON.stringify(error))
							}
						);
					})
					.catch(() => {});
			},
			//立即还款
			immediateRepayment(e) {
				console.log(e);
				if (Math.abs(e.fEndBalance) == 0) {
					this.$message({
						message: '亲,您当前的待还款金额为零哦~',
						type: 'success'
					});
					return
				}
				let obj = {
					title: '授信还款',
					walletType: 'SX',
					shopName: e.fShopName,
					maxAmount: Math.abs(e.fEndBalance),
					shopID: e.fShopUnitID,
					lastTime: e.fCreateTime
				}
				this.$router.push({
					path: '/businessme/ReachCreditAndTicket',
					query: {
						// data: encodeURIComponent(JSON.stringify(obj))
						data: JSON.stringify(obj)
					}
				});
			},
			//作废
			toVoid(e) {
				// console.log('作废', e);
				this.$confirm("是否确认作废?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.ApiRequestPost(
							"api/mall/ebsale/credit-limits-requisition/to-void", [{
								id: e,
							}],
						).then(
							(result) => {
								// console.log(result);
								this.getapplyCreditList(this.shopId);
							},
							(error) => {}
						);
					})
					.catch(() => {});
			}
		},
	};
</script>
<style scoped lang="scss">
	.BillWallet-Box {
		width: 100%;
	}

	.btncla {
		border-width: 0px;
		margin-top: 11px;
		left: 0px;
		top: 0px;
		width: 235px;
		height: 45px;
		background: inherit;
		background-color: rgba(1, 115, 254, 1);
		border: none;
		border-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-size: 14px;
		color: #FFFFFF;
	}

	.margin-b-7 {
		margin-bottom: 7px;
	}

	.creditLine-box {
		width: 453px;
		height: 175px;
		background-color: rgba(255, 255, 255, 1);
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(236, 237, 240, 1);
		box-shadow: 2px 2px 10px #badbf9;
	}

	.creditLine-box img {
		width: 450px;
		height: 173px;
	}

	.position-a-box {
		left: 0;
		top: 0;
		opacity: 0.9;
		width: 450px;
		height: 173px;
	}

	.creditLine-cont {
		left: 0;
		top: 0;
		width: 410px;
		padding: 13px 13px 13px 25px;
	}

	.orderd {
		padding: 15px;
		margin-top: 10px;
		width: 885px;
		height: 40px;
		border-radius: 5px;
		border: 1px solid #f2f2f2;
	}

	.width-haff {
		width: 50%;
		padding: 8px 0;
	}

	.apply-btn,
	.backmoney-btn {
		border: 1px solid #4f5467;
		text-align: center;
		width: 120px;
		height: 40px;
		line-height: 40px;
		font-size: 14px;
	}

	.apply-btn {
		color: #4f5467;
	}

	.backmoney-btn {
		background: #4f5467;
	}

	.color-C9983D {
		color: #c9983d;
	}

	.ter {
		text-align: right;
	}

	.wid8 {
		width: 80%;
	}

	.wid2 {
		width: 20%;
	}

	.tipsd {
		margin-left: 3px;
		width: 43px;
		height: 17px;
		line-height: 17px;
		border-radius: 2px;
		font-size: 12px;
		font-weight: 400;
		color: #ffffff;
		padding: 0 5px;
		background: #40c6a9;
	}
	.tipsds{
		margin-left: 3px;
		width: 43px;
		height: 17px;
		line-height: 17px;
		border-radius: 2px;
		font-size: 12px;
		font-weight: 400;
		color: #ffffff;
		padding: 0 5px;
		background: #999;
	}

	.operation-btn {
		width: 65px;
		height: 20px;
		line-height: 20px;
		border-radius: 10px;
	}

	.ml15 {
		margin-left: 15px;
	}

	.otn {
		text-align: center;
		width: 110px;
		height: 30px;
		line-height: 30px;
		background: #0173fe;
		border-radius: 2px;
		font-size: 14px;
		right: 0;
		position: absolute;
		font-weight: 400;
		color: #ffffff;
	}

	// .tabone-num {
	//   font-family: "Arial Negreta", "Arial Normal", "Arial";
	//   font-weight: 700;
	//   font-style: normal;
	//   font-size: 20px;
	//   color: #6c40bf;
	//   text-align: center;
	// }
	// .tabone-con {
	//   font-size: 14px;
	//   color: #6c40bf;
	//   border-bottom: none;
	// }

	.myPointsContent {
		width: 917px;
		min-height: 485px;
		background-color: #ffffff;
		// padding:0px 13px;
	}

	.allOrder-btn {
		display: flex;
	}

	.allOrder-btn div:nth-child(1) {
		width: 75px;
		height: 32px;
		margin-right: 5px;
		text-align: center;
		line-height: 32px;
	}

	.allOrder-btn div:nth-child(2) {
		width: 110px;
		height: 32px;
		text-align: center;
		line-height: 32px;
	}

	.block {
		margin-left: 290px;
		margin-right: 10px;
	}

	::v-deep .el-table__row {
		height: 38px;
	}

	.red {
		color: #ff0000;
	}

	.black {
		color: #000;
	}

	/* tab切换标题 */
	.tabTitleRow {
		height: 40px;
		background-color: #f8f9fe;
		border: 1px solid #eee;
		margin-top: 17px;
		box-sizing: border-box;
		margin-bottom: 17px;
	}

	.tabTitleRow div {
		width: 108px;
		display: inline-block;
		height: 40px;
		line-height: 40px;
		color: #666;
		text-align: center;
		box-sizing: border-box;
	}

	.tabTitleRow div:first-child {
		border-left: none !important;
	}

	.tabTitleRow .checkTitleSty {
		@include themify($themes) {
			color: themed("themes_color");
		}

		font-weight: bold;
		background-color: #fff;
		border-left: 1px solid #eee;
		border-right: 1px solid #eee;
	}

	.line-height39 {
		line-height: 39px;
	}

	.billtext {
		line-height: 36px;
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.color-4F5467 {
		color: #4f5467;
	}

	.color-BBBDC5 {
		color: #bbbdc5;
	}

	.margin-r-10 {
		margin-right: 10px;
	}

	.pagination {
		margin: 10px 0;
	}
</style>
